export const optimizeImage = (imageUrl, imageFormatValue, imageQualityValue) => {
    let imageFormat = imageFormatValue ? imageFormatValue : 'webp';
    let imageQuality = imageQualityValue ? imageQualityValue : 85;
    if((imageUrl !== undefined) && (imageUrl !== null) && (imageUrl.includes('images.ctfassets.net')) && (imageUrl.includes('fm=webp') == false) && (imageUrl.includes('.svg') == false)) {
        if(imageUrl?.includes('https')) {
            if(imageUrl?.includes('?') ) {
                imageUrl = imageUrl + `&fm=${imageFormat}` + `&q=${imageQuality}`;
            }
            else {
                imageUrl = imageUrl + `?fm=${imageFormat}` + `&q=${imageQuality}`;
            }
        }
        else {
            imageUrl = 'https:' + imageUrl
            if(imageUrl?.includes('?')) {
                imageUrl = imageUrl + `&fm=${imageFormat}` + `&q=${imageQuality}`;
            }
            else {
                imageUrl = imageUrl + `?fm=${imageFormat}` + `&q=${imageQuality}`;
            }
        }
    }
    return imageUrl;
}